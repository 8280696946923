// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

interface ErrorWithCause extends Error {
  cause?: {
    status?: number;
  };
}

function shouldIgnoreError(exception: Error | string | null): boolean {
  if (typeof exception === 'string' || exception === null) {
    return false;
  }

  const errorWithCause = exception as ErrorWithCause;
  const status = errorWithCause.cause?.status;
  // If we get a 400 level error from DAPI we assume it's being handled in the front-end
  return status !== undefined && status >= 400 && status < 500;
}

Sentry.init({
  dsn: SENTRY_DSN || 'https://82b1c71a417441dfb78798e1782f324a@o570890.ingest.sentry.io/107173',
  beforeSend(event: Sentry.Event, hint?: Sentry.EventHint): Sentry.Event | null {
    const originalException = hint?.originalException;
    if (originalException && shouldIgnoreError(originalException)) {
      return null;
    }
    return event;
  },
  environment: process.env.NEXT_PUBLIC_SOLV_ENV || 'production',
  tracesSampleRate: 0.0,
});
